import React, { ReactElement } from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Box, Fade, Popper, Paper, ClickAwayListener } from "@mui/material";

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  popper: {
    zIndex: 2000,
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: "-0.71em",
      marginLeft: 4,
      marginRight: 4,
      "&::before": {
        transformOrigin: "0 100%",
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: "-0.71em",
      marginLeft: 4,
      marginRight: 4,
      "&::before": {
        transformOrigin: "100% 0",
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: "-0.71em",
      height: "1em",
      width: "0.71em",
      marginTop: 4,
      marginBottom: 4,
      "&::before": {
        transformOrigin: "100% 100%",
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: "-0.71em",
      height: "1em",
      width: "0.71em",
      marginTop: 4,
      marginBottom: 4,
      "&::before": {
        transformOrigin: "0 0",
      },
    },
  },
  // Stolen from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Tooltip/Tooltip.js
  arrow: {
    // overflow: 'hidden',
    // position: 'absolute',
    // width: '4em',
    // height: '1em' /* = width / sqrt(2) = (length of the hypotenuse) */,
    // boxSizing: 'border-box',
    // boxShadow: '0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)',
    // // color: theme.palette.background.paper,
    // '&::before': {
    // 	content: '""',
    // 	margin: 'auto',
    // 	display: 'block',
    // 	width: '100%',
    // 	height: '100%',
    // 	backgroundColor: 'currentColor',
    // 	transform: 'rotate(45deg)'
    // }
  },
}));

interface Props {
  content: ReactElement;
  children: ReactElement;
  open: boolean;
  onClose?: () => void;
  arrow?: boolean;
  placement?: string;
}

const PopperPopupState = React.memo((props: Props) => {
  const classes = useStyles();
  const { open, content, onClose, anchorEl } = props;

  const [arrowRef, setArrowRef] = React.useState(null);
  const [arrow] = React.useState(true);

  return (
    <Popper
      sx={{ marginRight: "-30px !important", borderRadius: "12px" }}
      open={open}
      anchorEl={anchorEl}
      placement="bottom-end"
      transition
      className={classes.popper}
      disablePortal={true}
      modifiers={{
        flip: {
          enabled: true,
        },
        preventOverflow: {
          enabled: true,
          boundariesElement: "window",
        },
        arrow: {
          enabled: arrow,
          element: arrowRef,
        },
      }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Paper sx={{ borderRadius: "12px" }}>
            <ClickAwayListener onClickAway={onClose}>
              <Paper className={classes.popoverRoot} sx={{ borderRadius: "12px" }}>
                {arrow ? <span className={classes.arrow} ref={setArrowRef} /> : null}
                <Box className={classes.content}>{content}</Box>
              </Paper>
            </ClickAwayListener>
          </Paper>
        </Fade>
      )}
    </Popper>
  );
});

export default PopperPopupState;
